import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full"
}
const _hoisted_2 = { class: "flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StreamBarcodeReader = _resolveComponent("StreamBarcodeReader")!
  const _component_vxe_modal = _resolveComponent("vxe-modal")!

  return (_openBlock(), _createBlock(_component_vxe_modal, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
    title: "QRCode掃描器",
    "show-footer": "",
    width: "80vw",
    height: "80vh",
    transfer: "",
    onClose: _ctx.close,
    onHide: _ctx.close
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "button text-lg bg-theme-1 mr-2 text-white"
        }, "關閉")
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_StreamBarcodeReader, { onDecode: _ctx.onDecode }, null, 8, ["onDecode"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onClose", "onHide"]))
}