
import { defineComponent, toRef, watch } from "vue";
import { StreamBarcodeReader } from "vue-barcode-reader"

export default defineComponent({
  components: {
    StreamBarcodeReader,
  },
  props: {
    visible: { type: Boolean, default: true },
  },
  setup(props) {
    const open = toRef(props, "visible");

    watch(
      () => open,
      (current) => {
        console.log("open", current);
      }
    );
    return { open };
  },
  methods: {
    close() {
      this.$emit("dismiss");
    },
    isBase64(str: string) {
      if (!str || !str.trim()) return false;
      try {
          return btoa(atob(str)) === str;
      } catch (e) {
          return false;
      }
    },
    onDecode(value: any) {
      var result = this.isBase64(value) ? window.atob(value) : value;
      this.$emit("decode", result);
    },
  },
});
